.cards-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 15px;
}

.Vcard {
  padding: 30px;
  border-radius: 5px;
  color: white;
  width: 100%;
}

.Hcard {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}

.Hcard .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
}

@media (max-width: 600px) {
  .cards-grid{
    grid-gap: 0px;
  }
  .Vcard {
    padding: 10px 20px;
  }
}
