@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --primary-color: #0694a2;
  --blue-color: #3f83f8;
  --orange-color: #fe6262;
}

body {
  background: rgb(243, 243, 243);
}

.sidebar,
.Navbar {
  background: white;
}

.Navbar {
  position: fixed !important;
  top: 0%;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%);
}

.Navbar .humburger {
  position: absolute;
  left: 3%;
}

.sidebar {
  position: fixed;
  top: 0px;
  width: 20%;
  height: 100vh;
  z-index: 2000;
}

.sidebar a {
  text-decoration: none;
  color: gray;
}

.active {
  color: var(--primary-color) !important;
  font-weight: bold;
}

.sidebar .activeSpan {
  position: absolute;
  left: 0%;
  width: 5px;
  background: var(--primary-color);
  height: 35px;
  border-radius: 5px;
}

.sidebar .logo {
  color: black;
}

.sidebar-btn {
  position: absolute;
  top: 85%;
  width: 100%;
}

.sidebar-btn button {
  background: var(--orange-color);
  width: 100%;
  color: white;
}

.content {
  margin-left: 22%;
  margin-top: 75px;
  width: 78%;
}

.Tablee {
  background: white;
  border-radius: 15px;
  border-color: white;
  border-bottom: 0px;
}

.Tablee thead {
  border-bottom: 1px solid gainsboro;
}

.Tablee thead tr th:first-child {
  border-top-left-radius: 15px;
}

.Tablee thead tr th:last-child {
  border-top-right-radius: 15px;
}

.Tablee th {
  white-space: nowrap;
  padding: 10px 15px;
}

.Tablee td {
  white-space: nowrap;
  padding: 10px 15px;
  border-bottom: 1px solid gainsboro;
  vertical-align: middle;
}

.primary {
  background: var(--primary-color);
}

.blue {
  background: var(--blue-color);
}

.orange {
  background: var(--orange-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px !important;
}

@media (max-width: 1023px) {
  .Navbar {
    height: 55px;
  }
  .sidebar {
    left: 0%;
    top: 53px;
    animation: animateSidebar 0.4s linear;
    width: 30%;
  }
  .sidebar-btn {
    top: 70%;
  }

  @keyframes animateSidebar {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }

  .content {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 750px) {
  .sidebar {
    width: 40%;
  }
}

@media (max-width: 500px) {
  .sidebar {
    width: 50%;
  }
}

@media (max-width: 400px) {
  .sidebar {
    width: 60%;
  }
}

