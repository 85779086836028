.Products {
  width: 98%;
}

.Products .button {
  background: var(--primary-color);
  color: white;
}

.Products .searchBox {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
  padding: 20px 30px;
  background: white;
  border-radius: 10px;
  margin-top: 20px;
}

.Products .searchBox .input {
  background: rgb(241, 241, 241);
  padding: 10px;
}

.Products .searchBox .input:focus {
  background: white;
}

.ProductModal,
.modal-backdrop {
  z-index: 3000;
}

.ProductModal .modal-dialog {
  max-width: 70% !important;
}

@media (max-width: 600px) {
  .ProductModal {
    height: 100vh;
    --bs-modal-margin: 0 !important;
    --bs-modal-border-width: 0px;
    padding: 0;
  }
  .ProductModal .modal-dialog {
    max-width: 100% !important;
    height: 100vh;
  }
  .modal-backdrop {
    display: none;
  }
  .ProductModal .modal-content {
    height: 100vh;
  }
}
