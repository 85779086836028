.Login {
  height: 100vh;
  width: 100%;
}

.LoginBox {
  background: white;
  padding: 20px;
  width: 50%;
  border-radius: 10px;
}
